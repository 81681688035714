<template>
	<div class="drawer drawer-end ">	
		<input id="location_drawer" type="checkbox" class="drawer-toggle" />
		<div class="drawer-content">
			<div class="p-4 lg:p-6 pt-0 lg:pt-0 h-full">
				<div class="flex justify-between items-center pb-4">									
					<div class="text-2xl font-bold">Locations</div>					
					<label for="location_drawer" class="btn btn-square btn-outline btn-sm no-animation">
						<PlusIcon  class="h-5 w-5" aria-hidden="true" />  					
					</label>
				</div>

				<div class="w-full bg-base-100" v-if="!locations">      					
					<div class="overflow-x-auto">
						<table class="table">            
							<thead>
								<tr>								
									<th>Location</th>									
									<th>Status</th>            
									<th>Default</th>            
								</tr>
							</thead>
							<tbody>              
								<tr class="">							
									<td class="w-1/3">
										<div class="skeleton w-12 h-5"></div>
									</td>
									<td class="w-1/3">
										<div class="skeleton w-12 h-5"></div>
									</td>	
									<td class="w-1/3">
										<div class="skeleton w-12 h-5"></div>
									</td>								
								</tr>							
							</tbody>                               
						</table>						
					</div>
				</div>

				<div class="card-bordered card-compact rounded-lg shrink-0 w-full bg-base-100" v-if="locations && locations.length == 0">     
					<div class="card-body text-center">
						Add your first location using the plus button in the top-right corner
					</div>
				</div>

				<div class="w-full bg-base-100" v-if="locations && locations.length > 0">      					
					<div class="overflow-x-auto">
						<table class="table">            
							<thead>
								<tr>								
									<th>Location</th>									
									<th>Status</th>            
									<th>Default</th>            
								</tr>
							</thead>
							<tbody>              
								<tr v-for="location in locations.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))" v-bind:key="location.id" class="cursor-pointer hover:bg-base-200" @click="goTo(location)">
									<td class="w-1/3">																					
										{{location.name}}									
									</td>									
									<td class="w-1/3">
										{{location.active ? "Active" : "Inactive"}}
									</td>
									<td class="w-1/3">
										{{location.default ? "Default" : ""}}
									</td>								
								</tr>							
							</tbody>                               
						</table>						
					</div>
				</div>
			</div>
		</div>
		<LocationDrawer v-if="location" :location="location" :handler="createLocation" :toggle="toggle"></LocationDrawer>	
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import { PlusIcon } from '@heroicons/vue/24/outline' 
	import LocationDrawer from '@/components/LocationDrawer.vue' 
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_Locations",			
		components: {
			PlusIcon, LocationDrawer
		},	
		setup() {
			const route = useRoute()
			const router = useRouter()

			const locations = ref(null)
			const location = ref({})

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {    				           
				axios.get(`/api/provider/${route.params.provider_id}/locations`)
				.then( res => { 					
					locations.value = res.data.locations
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			function goTo(location) {
				router.push(`/provider/${route.params.provider_id}/location/${location.id}`)
			}

			function toggle() {
				const checkbox = document.getElementById('location_drawer');
				checkbox.checked = !checkbox.checked;
			}

			async function createLocation(newLocation) {
				if (!newLocation.name) {
					return useToast().warning('A name is required for a location.', { position: "top-right" });
				}				

				let data = {					
					name: newLocation.name,		
					provider: route.params.provider_id,
					active: true
				}	

				let loader = loading.show()
				axios.post(`/api/provider/${route.params.provider_id}/location`, data)
				.then( res => { 		
					location.value = {
						name: null
					}
					locations.value.push(res.data.location)
					useToast().success('The location has been created.', { position: "top-right" });
					loader.hide()
					toggle()	

				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error creating the location, please try again.', { position: "top-right" });
					console.log(e)
				}) 
			} 

			return {
				route,
				location,
				locations,
				goTo,
				PlusIcon,
				toggle,
				createLocation,
				LocationDrawer
			}
		}
	};
</script>
