<template>
	<OrderComponent :handler="updateOrder" :goBack="utils.goBack" :order="order" :user="'Provider'"></OrderComponent>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import OrderComponent from "@/components/OrderComponent.vue";
	import utils from "@/helpers/utils.js"
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_Order",			
		components: {
			OrderComponent
		},
		setup() {
			const route = useRoute()
			const router = useRouter()

			const order = ref(null)   			

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/order/${route.params.order_id}`)
				.then( res => { 					
					order.value = res.data.order
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			async function updateOrder(data) {		
				let loader = loading.show()		
				axios.put(`/api/provider/${route.params.provider_id}/order/${route.params.order_id}`, data)
				.then( res => { 			
					loader.hide()
					useToast().success('The order has been updated.', { position: "top-right" });		
					order.value = res.data.order
				})
				.catch(e => {
					loader.hide()
					console.log(e)
				}) 
			} 

			return {
				route,
				router,
				utils,
				order,
				updateOrder
			}
		}
	};
</script>
