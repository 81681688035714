import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Client_Home from "../views/Client_Home.vue";
import Client_Order from "../views/Client_Order.vue";
import Client_New_Order from "../views/Client_New_Order.vue";
import Provider_Home from "../views/Provider_Home.vue";
import Provider_Inventory from "../views/Provider_Inventory.vue";
import Provider_Locations from "../views/Provider_Locations.vue";
import Provider_Location from "../views/Provider_Location.vue";
import Provider_Products from "../views/Provider_Products.vue";
import Provider_Product from "../views/Provider_Product.vue";
import Provider_Orders from "../views/Provider_Orders.vue";
import Provider_Clients from "../views/Provider_Clients.vue";
import Provider_Client from "../views/Provider_Client.vue";
import Provider_Order from "../views/Provider_Order.vue";
import Provider_Transactions from "../views/Provider_Transactions.vue";
import Provider_New_Order from "../views/Provider_New_Order.vue";
import LoginView from "../views/LoginView.vue";
import SignupView from "../views/SignupView.vue";
import ResetView from "../views/ResetView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  }, {
    path: "/client/:client_id",
    name: "Client_Home",
    component: Client_Home,
  },{
    path: "/client/:client_id/order/:order_id",
    name: "Client_Order",
    component: Client_Order,
  },{
    path: "/client/:client_id/order",
    name: "Client_New_Order",
    component: Client_New_Order,
  },{
    path: "/provider/:provider_id/adjust",
    name: "Provider_Adjust",
    component: Provider_Inventory,
  },{
    path: "/provider/:provider_id/inbox",
    name: "Provider_Home",
    component: Provider_Home,
  },{
    path: "/provider/:provider_id/inventory",
    name: "Provider_Inventory",
    component: Provider_Inventory,
  },{
    path: "/provider/:provider_id/orders",
    name: "Provider_Orders",
    component: Provider_Orders,
  },{
    path: "/provider/:provider_id/clients",
    name: "Provider_Clients",
    component: Provider_Clients,
  },{
    path: "/provider/:provider_id/client/:client_id",
    name: "Provider_Client",
    component: Provider_Client,
  },{
    path: "/provider/:provider_id/locations",
    name: "Provider_Locations",
    component: Provider_Locations,
  },{
    path: "/provider/:provider_id/location/:location_id",
    name: "Provider_Location",
    component: Provider_Location,
  },{
    path: "/provider/:provider_id/products",
    name: "Provider_Products",
    component: Provider_Products,
  },{
    path: "/provider/:provider_id/product/:product_id",
    name: "Provider_Product",
    component: Provider_Product,
  },{
    path: "/provider/:provider_id/order/:order_id",
    name: "Provider_Order",
    component: Provider_Order,
  },{
    path: "/provider/:provider_id/print/:order_id",
    name: "Provider_Order_Print",
    component: Provider_Order,
  },{
    path: "/provider/:provider_id/transactions",
    name: "Provider_Transactions",
    component: Provider_Transactions,
  },{
    path: "/provider/:provider_id/transaction/:order_id",
    name: "Provider_Transaction",
    component: Provider_Order,
  },{
    path: "/provider/:provider_id/order",
    name: "Provider_New_Order",
    component: Provider_New_Order,
  },{
    path: "/provider/:provider_id/transaction",
    name: "Provider_New_Transaction",
    component: Provider_New_Order,
  },{
    path: "/login",
    name: "Login",
    component: LoginView,
  },{
    path: "/signup",
    name: "Signup",
    component: SignupView,
  },{
    path: "/reset",
    name: "Reset Password",
    component: ResetView,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import { useStore } from 'vuex'

router.beforeEach( to => {
  console.log(to)
  if (!to.name) {
    return true //Better check here
  }
  
  if (to.name == "Home") {
    return true
  } else if (to.name == "Login") {
    return true
  } else if (to.name == "Signup") {
    return true
  } else if (to.name == "Reset Password") {
    return true
  }

  if (to.name.startsWith("Client_")) {
    return true
  }

  let store = useStore()

  if (!store.state.session || !store.state.session.providers) {
    // console.log('Redirecitng to login', store.state.session, store.state.session.providers)
    return window.location.href = "/login";
  }

  let ids = store.state.session.providers.map( p => p.provider.id)
  // console.log("ids", ids, to.params.provider_id)

  if (to.params.provider_id && ids.includes(Number(to.params.provider_id))) {
    return true
  }

  // console.log('Clearing session', store.state.session, store.state.session.providers)
  store.commit("setSession", null)            
  return window.location.href = "/login";
})

export default router;
