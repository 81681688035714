<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full overflow-y-scroll">
		<div class="text-2xl font-bold pb-4">New order</div>
		<select v-if="providerClients && providerClients.length > 1" class="select select-bordered w-full mb-6" v-model="providerClient">
			<option :value="null" disabled selected>Select a provider</option>
			<option v-for="providerClient in providerClients" v-bind:key="providerClient.id" :value="providerClient">{{providerClient.provider.name}}</option>
		</select>			
		<NewOrderComponent v-if="providerClient" :products="products" :handler="createOrder"/>	
	</div>
</template>

<script>	
	import { onMounted, ref, computed } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import NewOrderComponent from "@/components/NewOrderComponent.vue";
	import utils from "@/helpers/utils.js"
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';	

	export default {
		name: "Client_New_Order",			
		components: {
			NewOrderComponent
		},
		setup() {
			const route = useRoute()
			const router = useRouter()

			const location = ref(null)

			const providerClients = ref(null)   			
			const providerClient = ref(null)   			

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {    
				let loader = loading.show()            
				axios.get(`/api/client/${route.params.client_id}/provider_client`)
				.then( res => { 									
					loader.hide()
					providerClients.value = res.data.providerClients

					if (providerClients.value.length == 1) {
						providerClient.value = providerClients.value[0]
						console.log('here')
						getDefaultLocation(providerClient.value.provider.id)
					} 
				})
				.catch(e => {
					loader.hide()
					console.log(e)
				}) 
			})						

			const products = computed( () => {
				if (!providerClient.value) { 
					return [] 
				}

				return providerClient.value.provider.products.map( p => {
					p.quantity = 0
					return p
				})
			})

			async function getDefaultLocation(provider_id) {
				axios.get(`/api/provider/${provider_id}/locations`)
				.then( res => { 				
					let defaultLoc = res.data.locations.find( l => l.default )

					if (defaultLoc) {
						location.value = defaultLoc
					}					
				})
				.catch(e => {
					console.log(e)
				}) 
			}

			function createOrder(orderProducts) {
				if (orderProducts.filter( p => p.quantity > 0).length == 0) {
					return useToast().warning('At least one product is requiered for an order.', { position: "top-right" });
				}

				if (!location.value) {
					return useToast().warning('Please reach out to your provider, a default location is missing.', { position: "top-right" });
				}

				let data = { 				
					clientId: route.params.client_id, 
					providerId: providerClient.value.provider.id,
					locationId: location.value.id,
					products: orderProducts.filter( p => p.quantity > 0),
					transaction_type: "ORDER"
				}

				let loader = loading.show()            
				axios.post('/api/order', {
					order: JSON.stringify(data) 
				})
				.then( res => { 				
					loader.hide()	
					useToast().success('The order has been created.', { position: "top-right" });
					router.push(`/client/${route.params.client_id}/order/${res.data.order.id}`)
				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error creating the order, please try again.', { position: "top-right" });
					console.log(e)
				}) 
			}

			return {
				route,
				router,
				utils,
				providerClients,
				providerClient,
				products,
				createOrder,
				location
			}
		}
	};
</script>
