	<template>  
		<div class="w-full h-full">
			<div class="navbar bg-base-100">
				<div class="navbar-start">

			<a class="btn btn-ghost text-xl">Poink</a>
		</div>
		
		<div class="navbar-end">
			<a @click="go" class="btn">{{ session ? "Inbox" : "Login" }}</a>
		</div>
	</div>
</div>

	<!-- 
		<div class="w-full h-full flex items-center justify-center">
			Home
		</div> -->
	</template>

	<script>
		import { onMounted, ref } from "vue"
		import { useRouter } from 'vue-router'
		import { useStore } from 'vuex'

		export default {
			name: "Home_View",
			setup() {
				const router = useRouter()
				const store = useStore()
				const session = ref(false)
				const providerId = ref()

				onMounted( async () => {
					try {						
						providerId.value = store.state.session.providers[0].id

						if (providerId.value) {
							session.value = true
						}
					} catch(e) {
						console.log(e)
					}
				})

				function go() {
					if (providerId.value) {
						router.push(`/provider/${store.state.session.providers[0].id}/inbox`)				
					} else {
						router.push(`/login`)				
					}
				}

				return {
					go,
					session
				}	
			}
		};
	</script>