<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full">
		<div class="flex justify-between items-center pb-4">									
			<div class="text-2xl font-bold">Orders</div>					
			<label class="btn btn-square btn-outline btn-sm no-animation">
				<router-link :to=" `/provider/${route.params.provider_id}/order` ">
					<PlusIcon  class="h-5 w-5" aria-hidden="true" />  			
				</router-link>		
			</label>
		</div>

		<OrdersComponent :user="'provider'" :type="'orders'" :orders="orders" :handler="goTo" :empty="'Add your first order using the plus button in the top-right corner'"></OrdersComponent>		
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import OrdersComponent from "@/components/OrdersComponent.vue";
	import { PlusIcon } from '@heroicons/vue/24/outline' 
	
	export default {
		name: "Provider_Orders",			
		components: {
			OrdersComponent, PlusIcon
		},
		setup() {
			const route = useRoute()
			const router = useRouter()
			const orders = ref(null)
			
			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/orders?type=ORDER`)
				.then( res => { 
					orders.value = res.data.orders
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			function goTo(order) {
				router.push(`/provider/${route.params.provider_id}/order/${order.id}`)				
			}

			return {
				route,
				orders,				
				goTo
			}
		}
	};
</script>
