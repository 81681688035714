<template>  
	<div class="w-full h-full flex items-center justify-center">
		<div class="card-bordered rounded-lg shrink-0 w-full max-w-sm bg-base-100">			
			<div class="card-body">
				<div class="text-xl">Signup</div>
				<div class="form-control">
					<label class="label">
						<span class="label-text">Email</span>
					</label>
					<input type="email" placeholder="email" v-model="email" class="input input-bordered input-sm" required />
				</div>
				<div class="form-control">
					<label class="label">
						<span class="label-text">Business name</span>
					</label>
					<input type="text" placeholder="name" v-model="name" class="input input-bordered input-sm" required />
				</div>
				<div class="form-control">
					<label class="label">
						<span class="label-text">Password</span>
					</label>
					<input type="password" placeholder="password" v-model="password" class="input input-bordered input-sm" required @keyup.enter="signup"/>					
				</div>
				<div class="form-control mt-6">
					<button class="btn btn-primary text-white no-animation btn-sm" @click="signup">Signup</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { onMounted, ref } from "vue"
	import { useRouter } from 'vue-router'
	import axios from "@/axios.js"
	import { useStore } from 'vuex'
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "LoginView",
		setup() {
			const store = useStore()
			const router = useRouter()
			const email = ref(null)
			const name = ref(null)
			const password = ref(null)

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {

			})

			async function signup() {
				if (!email.value || !password.value) {
					return alert('Make sure you filled in all the fields')
				}

				if (password.value.length <=6 ) {
					return useToast().warning('The password must be greater than 6 characters.', { position: "top-right" });
				}

				let loader = loading.show()
				axios.post("/api/auth/signup", { email: email.value, password: password.value, name: name.value })
				.then( res => {
					loader.hide()
					console.log("Response", res)
					if (res.data) {
						console.log(res.data)
						localStorage.setItem('enlazado_session', JSON.stringify(res.data))					
						store.commit("setSession", res.data)
						router.push(`/provider/${res.data.providers[0].id}/inbox`)
					} else {
						return alert('Error signing up')
					}
				})
				.catch( e => {
					loader.hide()
					console.log(e)
					return alert('Error signing up')
				})				
			}

			return {
				signup,
				email,
				password,
				name
			}
		}
	};
</script>