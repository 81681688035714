<template>
	<div v-if="order && order.transaction_type == 'ORDER'" class="">
		<div v-if="order.cancelled" class="h-full badge badge-ghost text-black">
			Cancelled
		</div>
		<div v-else-if="!order.cancelled && order.fulfilled" class="h-full badge badge-success text-white">
			Complete
		</div>
		<div v-else-if="!order.cancelled && !order.fulfilled" class="h-full badge badge-warning text-white">
			Pending
		</div>
	</div>
</template>

<script>
	export default {
		name: "StatusComponent",
		props: {
			order: Object,
		},
	};
</script>
