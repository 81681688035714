<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full">
		<div class="flex justify-between items-center pb-4">									
			<div class="text-2xl font-bold">Transactions</div>					
			<label class="btn btn-square btn-outline btn-sm no-animation">
				<router-link :to=" `/provider/${route.params.provider_id}/transaction` ">
					<PlusIcon  class="h-5 w-5" aria-hidden="true" />  			
				</router-link>		
			</label>
		</div>

		<div class="flex space-x-4 mb-4">
			<div class="w-full">
				Location
				<select class="select select-bordered select-sm w-full mt-2" v-if="locations" v-model="location">
					<option value="" selected>All</option>
					<!-- <option :value="null" disabled selected>Select a location</option> -->
					<option v-for="location in locations" v-bind:key="location.id" :value="location">{{location.name}}</option>
				</select>
			</div>

			<div class="w-full">
				Transaction type 
				<select class="select select-bordered select-sm w-full mt-2" v-model="transactionType">
					<option value="" selected>All</option>
					<option value="ORDER">Order</option>
					<option value="ADJUST">Adjustment</option>
					<option value="IN">In</option>
					<option value="OUT">Out</option>		
				</select>
			</div>
		</div>	

		<div class="flex space-x-4 mb-4">
			<div class="w-full">
				Start date
				<input class="input input-bordered input-sm w-full mt-2" v-model="startDate" type="date">
			</div>
			<div class="w-full">
				End date
				<input class="input  input-bordered input-sm w-full mt-2" v-model="endDate" type="date">
			</div>
		</div>


		<div class="pb-4 flex-none">
			<button @click="filter()" class="btn btn-success btn-sm w-full text-white no-animation">Filter</button>
		</div>

		<OrdersComponent :user="'provider'" :type="'transactions'" :orders="orders" :handler="goTo" :empty="'There are no orders matching your filter'"></OrdersComponent>		
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import OrdersComponent from "@/components/OrdersComponent.vue";
	import { PlusIcon } from '@heroicons/vue/24/outline' 
	
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: "Provider_Transactions",			
		components: {
			OrdersComponent, PlusIcon
		},
		setup() {
			const route = useRoute()
			const router = useRouter()
			const orders = ref(null)

			const location = ref("")
			const locations = ref([])
			const transactionType = ref("")
			
			const startDate = ref()
			const endDate = ref()

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {                
				refresh(false)

				axios.get(`/api/provider/${route.params.provider_id}/locations`)
				.then( res => { 				
					console.log(res)	
					locations.value = res.data.locations.filter( l => l.active )		
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			function filter() {
				if (startDate.value && endDate.value && ( startDate.value > endDate.value )) {					
					return alert('Ensure that the start date is before the end date')
				}

				refresh(true)
			}

			async function refresh(showLoading) {
				let loader;

				let params = {}

				if (showLoading) {
					loader = loading.show()
				}

				if (startDate.value) {
					params.startDate = startDate.value
				}

				if (endDate.value) {
					params.endDate = endDate.value
				}

				if (transactionType.value) {
					params.type = transactionType.value
				}

				if (location.value) {
					params.location_id = location.value.id
				}

				axios.get(`/api/provider/${route.params.provider_id}/orders`, {
					params: params
				})
				.then( res => { 
					orders.value = res.data.orders
					if (showLoading) {
						loader.hide()
					}
				})
				.catch(e => {
					console.log(e)
					if (showLoading) {
						loader.hide()
					}
				}) 			
			}

			function goTo(order) {
				if (order.transaction_type == "ORDER") {
					return router.push(`/provider/${route.params.provider_id}/order/${order.id}`)				
				}
				router.push(`/provider/${route.params.provider_id}/transaction/${order.id}`)				
			}

			return {
				refresh,
				startDate,
				endDate,
				location,
				locations,
				transactionType,
				route,
				orders,				
				goTo,
				filter
			}
		}
	};
</script>
