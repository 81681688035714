<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full overflow-y-scroll">	
		<div class="text-2xl font-bold pb-4">{{ type == "order" ? "New order" : "Inventory"}}</div>

		<select class="select select-bordered select-sm w-full mb-2" v-if="providerClients && providerClients.length > 0 && type == 'order'" v-model="providerClient">
			<option :value="null" disabled selected>Select a client</option>
			<option v-for="providerClient in providerClients" v-bind:key="providerClient.id" :value="providerClient">{{providerClient.client.name}}</option>
		</select>	

		<select class="select select-bordered select-sm w-full mb-2" v-if="locations" v-model="location">
			<option :value="null" disabled selected>Select a location</option>
			<option v-for="location in locations" v-bind:key="location.id" :value="location">{{location.name}}</option>
		</select>

		<select class="select select-bordered select-sm w-full mb-2" v-if="type == 'transaction'" v-model="transactionType">
			<option :value="null" disabled selected>Select a movement type</option>
			<option value="IN">In</option>
			<option value="OUT">Out</option>			
		</select>

		<div class="card-bordered card-compact rounded-lg shrink-0 w-full bg-base-100" v-if="providerClients && providerClients.length == 0 && type == 'order'">
			<div class="card-body text-center">
				Add your first client to create your first order.
			</div>
		</div>

		<NewOrderComponent :type="type" :products="products" :handler="createOrder"/>		
	</div>
</template>

<script>	
	import { onMounted, ref, computed } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import NewOrderComponent from "@/components/NewOrderComponent.vue";
	import utils from "@/helpers/utils.js"
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_New_Order",			
		components: {
			NewOrderComponent
		},
		setup() {
			const route = useRoute()
			const router = useRouter()

			const products = ref([])   							
			const locations = ref([]) 			
			const location = ref(null)   							
			const providerClients = ref(null)
			const providerClient = ref(null)

			const transactionType = ref(null)

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			const type = computed( () => {
				return route.name == "Provider_New_Order" ? "order" : "transaction"
			})

			onMounted( async () => {   
				let loader = loading.show()

				axios.get(`/api/provider/${route.params.provider_id}/products`)
				.then( res => { 				
					console.log(res)	
					products.value = res.data.products.map( p => {
						p.quantity = 0
						return p
					})		
				})
				.catch(e => {
					console.log(e)
				}) 

				axios.get(`/api/provider/${route.params.provider_id}/locations`)
				.then( res => { 				
					console.log(res)	
					locations.value = res.data.locations.filter( l => l.active )

					if (locations.value.length == 1) {
						location.value = locations.value[0]
					} else if (location.value.length == 0) {
						return
					} else {
						location.value = locations.value.find( l => l.default )					
					}
				})
				.catch(e => {
					console.log(e)
				}) 
				
				axios.get(`/api/provider/${route.params.provider_id}/provider_clients`)
				.then( res => { 	
					loader.hide()			
					console.log(res)	
					providerClients.value = res.data.provider_clients					
				})
				.catch(e => {
					loader.hide()
					console.log(e)
				}) 

				if (route.name == "Provider_New_Order") {
					transactionType.value = "ORDER"
				}
			})						

			function createOrder(orderProducts) {
				if (type.value == "order" && !providerClient.value) {
					return useToast().warning('Please select a client.', { position: "top-right" });
				}

				if (!location.value) {
					return useToast().warning('Please select a location.', { position: "top-right" });
				}

				if (type.value == "transaction" && !transactionType.value) {
					return useToast().warning('Please select a movement type.', { position: "top-right" });
				}

				if (transactionType.value == "ORDER" && orderProducts.filter( p => p.quantity < 0).length > 0) {
					return useToast().warning('Orders must have positive quantities.', { position: "top-right" });
				}

				if (transactionType.value == "IN" && orderProducts.filter( p => p.quantity < 0).length > 0) {
					return useToast().warning('Ins must have positive quantities.', { position: "top-right" });
				}

				if (transactionType.value == "OUT" && orderProducts.filter( p => p.quantity < 0).length > 0) {
					return useToast().warning('Outs must have positive quantities.', { position: "top-right" });
				}

				if (transactionType.value != "ADJUST" && orderProducts.filter( p => p.quantity > 0).length == 0) {
					return useToast().warning('At least one product is requiered for an order.', { position: "top-right" });
				}

				let data = { 									
					providerId: route.params.provider_id,
					locationId: location.value.id,
					products: orderProducts.filter( p => p.quantity != 0),
					transaction_type: transactionType.value
				}

				if (providerClient.value) {
					data.clientId = providerClient.value.client.id
				}
				
				let loader = loading.show()
				axios.post('/api/order', {
					order: JSON.stringify(data) 
				})
				.then( res => { 				
					console.log(res)	
					useToast().success('The order has been created.', { position: "top-right" });
					loader.hide()

					if (type.value == "order") {
						router.push(`/provider/${route.params.provider_id}/order/${res.data.order.id}`)
					} else {
						router.push(`/provider/${route.params.provider_id}/transaction/${res.data.order.id}`)
					}
				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error creating the order, please try again.', { position: "top-right" });
					console.log(e)
				}) 
			}

			return {
				route,
				router,
				utils,
				providerClients,
				providerClient,
				products,
				createOrder,
				location,
				locations,
				type,
				transactionType
			}
		}
	};
</script>
