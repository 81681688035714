import axios from "axios";
const axiosApiInstance = axios.create();

import store from "@/store/index.js"

axiosApiInstance.interceptors.request.use(
	(config) => {
		console.log("Config", config)
		if (localStorage.getItem('enlazado_session')) {
			try {
				let session = JSON.parse(localStorage.getItem('enlazado_session'));
				
				console.log('session', session)
				config.headers = {
					"Authorization": `Bearer ${session.user.session.access_token}`,
					"Accept": "application/json",
					// "Content-Type": "application/x-www-form-urlencoded"
				}
			} catch (e) {
				console.log("Error parsing session:", e)
			}
		}
		
		return config;
	},
	(error) => {
		console.log("Request error:", error)
		return Promise.reject(error);
	}
	);

axiosApiInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		console.log("Response error:", error)
		if (error.response.status === 401) {
			console.log("Unauthorized")
			let refreshToken = getRefreshToken()
			if (!refreshToken) {
				console.log('No refresh token, redirecting to login')
				return window.location.href = "/login";
			} else {			
				console.log('Refreshing token', refreshToken)
				const res = await axios.post("/api/auth/refresh", { refresh_token: refreshToken}).catch( () => {
					return window.location.href = "/login";
				})
				console.log('/api/auth/refresh', res)
				if (res.data.session) {
					console.log('Refreshed session:', res.data.session)

					let session = JSON.parse(localStorage.getItem('enlazado_session'));
					session.user.session = res.data.session
					
					store.commit("setSession", session)

					localStorage.setItem('enlazado_session', JSON.stringify(session))		

					let config = error.config;
					
					config.headers = {
						"Authorization": `Bearer ${res.data.session.access_token}`,
						"Accept": "application/json",
						"Content-Type": "application/x-www-form-urlencoded"
					}

					return axios.request(config);
				} else {
					return window.location.href = "/login";
				}	
			}

		} else {
			return Promise.reject(error);
		}		
	}
	);

function getRefreshToken() {
	if (!localStorage.getItem('enlazado_session')) {
		return 
	}

	let session = JSON.parse(localStorage.getItem('enlazado_session'));
	console.log('getRefreshToken, session:', session)
	return session.user.session.refresh_token
}

export default axiosApiInstance;