import { createClient } from '@supabase/supabase-js'
const supabase = createClient("https://wmxsoyhjaulrzsxhhihd.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndteHNveWhqYXVscnpzeGhoaWhkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTEyMTMyNzYsImV4cCI6MjAyNjc4OTI3Nn0.dOVUxMkgbkgJUUmJU4eta5JUdaupAB0lQHVrNDPGxcc")

import store from "@/store/index.js"

function goBack(router, backup) { 
    if (window.history.state.back === null) {        
        return router.push(backup)
    }
    
    window.history.back()
}

async function uploadFileHandler(type, evt) {  
    console.log(type, evt)

    if (!evt.target.files || evt.target.files.length === 0) {
        throw new Error("Selecciona un archivo")
    }
    const file = evt.target.files[0]
    const fileExt = file.name.split(".").pop()
    const fileName = `${Math.random()}.${fileExt}`
    const filePath = `${fileName}`

    return await uploadFile(type, filePath, file)                     
}

async function authListener() {
    supabase.auth.onAuthStateChange((event, session) => {
      console.log(event, session)

      if (event === 'INITIAL_SESSION') {
    // handle initial session
      } else if (event === 'SIGNED_IN') {
    // handle sign in event
      } else if (event === 'SIGNED_OUT') {
    // handle sign out event
      } else if (event === 'PASSWORD_RECOVERY') {
        
        let access_token = session.access_token
        session.user.session = { access_token: access_token }

        console.log('SAVING SESSION', session)
        localStorage.setItem('enlazado_session', JSON.stringify(session))        
        store.commit("setSession", session)      

    } else if (event === 'TOKEN_REFRESHED') {
    // handle token refreshed event
    } else if (event === 'USER_UPDATED') {
    // handle user updated event
    }
})
}

async function uploadFile(type, filePath, file) {
    let { data, error } = await supabase.storage
    .from(type)
    .upload(filePath, file)                         

    console.log("uploadFile", data, error)
    if (data && data.fullPath) {           
        return data.fullPath             
    } else if (error) {  
        console.log("utils uploadFile", error)
        throw error          
    } 
}

function getTransactionType(transactionType) {
    switch (transactionType) {
    case "ORDER":
        return "Order"
    case "IN":
        return "In"
    case "OUT":
        return "Out"
    case "ADJUST":
        return "Adjustment"
    }
}
const utils = {
    goBack: goBack,
    uploadFileHandler: uploadFileHandler,
    authListener: authListener,
    getTransactionType: getTransactionType
}

export default utils