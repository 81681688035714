<template>
	<div class="drawer drawer-end">	
		<input id="client_drawer" type="checkbox" class="drawer-toggle" />
		<div class="drawer-content">
			<div class="p-4 lg:p-6 pt-0 lg:pt-0 h-full">
				<div class="flex justify-between items-center pb-4">									
					<div class="text-2xl font-bold">Clients</div>					
					<label for="client_drawer" class="btn btn-square btn-outline btn-sm no-animation">
						<PlusIcon  class="h-5 w-5" aria-hidden="true" />  					
					</label>
				</div>	

				<div class="card-bordered card-compact rounded-lg shrink-0 w-full bg-base-100" v-if="provider_clients && provider_clients.length == 0">
					<div class="card-body text-center">
						Add your first client using the plus button in the top-right corner
					</div>
				</div>

				<div v-if="provider_clients && provider_clients.length > 0" class="w-full bg-base-100">      					
					<div class="overflow-x-auto" >
						<table class="table">            
							<thead>
								<tr>								
									<th>Name</th>								
									<th>Enabled</th>            
								</tr>
							</thead>
							<tbody>              
								<tr v-for="provider_client in provider_clients.sort((a,b) => (a.client.name.toLowerCase() > b.client.name.toLowerCase()) ? 1 : ((b.client.name.toLowerCase() > a.client.name.toLowerCase()) ? -1 : 0))" v-bind:key="provider_client.id" class="cursor-pointer hover:bg-base-200" @click="goTo(provider_client)">
									<td class="w-1/2">
										{{provider_client.client.name}}
									</td>								
									<td class="w-1/2">
										{{provider_client.active ? "Active" : "Inactive"}}
									</td>								
								</tr>							
							</tbody>                               
						</table>						
					</div>
				</div>

				<div v-if="!provider_clients" class="w-full bg-base-100">      					
					<div class="overflow-x-auto" >
						<table class="table">            
							<thead>
								<tr>								
									<th>Name</th>								
									<th>Enabled</th>            
								</tr>
							</thead>
							<tbody>              
								<tr class="">
									<td class="w-1/2">
										<div class="w-12 h-5 skeleton"></div>
									</td>								
									<td class="w-1/2">
										<div class="w-12 h-5 skeleton"></div>
									</td>								
								</tr>							
							</tbody>                               
						</table>						
					</div>
				</div>

			</div>
		</div>
		<ClientDrawer v-if="client" :client="client" :handler="createClient" :toggle="toggle"></ClientDrawer>	
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import { PlusIcon } from '@heroicons/vue/24/outline' 
	import ClientDrawer from '@/components/ClientDrawer.vue' 
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_Clients",		
		components: {
			PlusIcon, ClientDrawer
		},		
		setup() {
			const route = useRoute()
			const router = useRouter()

			const provider_clients = ref(null)
			const client = ref({})

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/provider_clients`)
				.then( res => { 
					console.log(res)
					provider_clients.value = res.data.provider_clients
				})
				.catch(e => {
					console.log(e)
				}) 
			})	

			function toggle() {
				const checkbox = document.getElementById('client_drawer');
				checkbox.checked = !checkbox.checked;
			}	

			function goTo(provider_client) {
				router.push(`/provider/${route.params.provider_id}/client/${provider_client.client.id}`)
			}

			async function createClient(newClient) {
				if (!newClient.name) {
					return useToast().warning('A name is required for a client.', { position: "top-right" });
				}

				if (!newClient.email) {
					return useToast().warning('An email is required for a client.', { position: "top-right" });
				}	

				let data = {
					name: newClient.name,
					email: newClient.email					
				}	

				let loader = loading.show()
				axios.post(`/api/provider/${route.params.provider_id}/client`, data)
				.then( res => { 		
					client.value = {}
					provider_clients.value.push(res.data.provider_client)
					useToast().success('The client has been created.', { position: "top-right" });
					loader.hide()
					toggle()	

				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error creating the client, please try again.', { position: "top-right" });
					console.log(e)
				}) 

			}

			return {
				route,
				provider_clients,
				goTo,
				toggle,
				client,
				createClient
			}
		}
	};
</script>
