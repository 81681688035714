<template>  
	<div class="w-full h-full flex items-center justify-center">
		<div class="card-bordered rounded-lg shrink-0 w-full max-w-sm bg-base-100">			
			<div class="card-body">
				<div class="text-xl">Reset password</div>
				<div class="form-control">
					<label class="label">
						<span class="label-text">Password</span>
					</label>
					<input type="password" placeholder="password" v-model="password" class="input input-bordered input-sm" required @keyup.enter="reset"/>					
				</div>
				<div class="form-control mt-6">
					<button class="btn btn-primary text-white no-animation btn-sm" @click="reset">Reset</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { onMounted, ref } from "vue"
	import { useRoute, useRouter } from 'vue-router'
	import axios from "@/axios.js"
	import { useStore } from 'vuex'
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';
	import utils from "@/helpers/utils.js"

	export default {
		name: "ResetView",
		setup() {
			const store = useStore()
			const router = useRouter()
			const route = useRoute()
			const password = ref(null)

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {
				console.log(route)
				utils.authListener()
			})

			async function reset() {				
				if (password.value.length <=6 ) {
					return useToast().warning('The password must be greater than 6 characters.', { position: "top-right" });
				}

				let session = JSON.parse(localStorage.getItem('enlazado_session'));

				let loader = loading.show()
				axios.post("/api/auth/reset", { 
					password: password.value, 
					auth: { 
						access_token: session.access_token, 
						refresh_token: session.refresh_token 
					} 
				})
				.then( res => {
					loader.hide()
					console.log("Response", res)
					if (res.data) {
						localStorage.setItem('enlazado_session', JSON.stringify(res.data))					
						store.commit("setSession", res.data)
						router.push(`/provider/${res.data.providers[0].provider.id}/inbox`)
					} else {
						return alert('Error resetting password')
					}
				})
				.catch( e => {
					loader.hide()
					console.log(e)
					return alert('Error resetting password')
				})				
			}

			return {
				reset,				
				password				
			}
		}
	};
</script>