<template>
	<div v-if="product" class="drawer-side">
		<label for="product_drawer" aria-label="close sidebar" class="drawer-overlay"></label>
		<div class="bg-white w-full lg:w-80 flex flex-col h-full">		
			<div class="flex-auto overflow-scroll p-6">
				<div class="flex justify-between items-center pb-6">
					<div class="text-2xl font-bold">{{product.id ? "Edit product" : "New product"}}</div>
					<div class="btn btn-square btn-outline btn-sm no-animation">
					<XMarkIcon class="h-5 w-5 cursor-pointer" aria-hidden="true" @click="toggle()"/>  				
					</div>
				</div>

				<p class="text-sm text-gray-400 pb-2">Name</p>
				<input v-model="tempProduct.name" type="text" placeholder="Name" class="bg-white input input-bordered input-sm w-full" />

				<p class="text-sm text-gray-400 pb-2 pt-4">Price</p>
				<input v-model="tempProduct.price" type="number" placeholder="0" class="bg-white input input-bordered input-sm w-full" />

				<div class="flex justify-between">
					<div class="pb-2 pt-4">Image</div>
					<div v-if="tempProduct.photo" @click="deleteFile()" class="pb-2 pt-4 underline cursor-pointer">Eliminar</div>
				</div>
				<div class="rounded-lg w-full aspect-square bg-gray-300">
					<div class="relative bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden" style=" aspect-ratio: 1 / 1 !important;" v-bind:style="{ backgroundImage: 'url(' + tempProduct.photo + ')' }">	
					</div>					
				</div>

				<div class="mt-4">					
					<input type="file" ref="file" id="product_foto" style="display: none" @change="uploadFile($event)" />					
					<button class="btn btn-outline btn-sm w-full no-animation" @click="$refs.file.click()" >Upload</button>
				</div>	
			
			</div>
			<div class="p-4 flex-none border-t border-t-gray-300">
				<button @click="handler(tempProduct)" class="btn btn-success btn-sm w-full text-white no-animation">Save</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { onMounted, ref } from "vue"		
	import { XMarkIcon } from '@heroicons/vue/24/outline' 	
	import utils from "@/helpers/utils.js"	
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "ProductDrawer",
		components: {
			XMarkIcon
		},	
		props: {
			product: Object,
			handler: Function,
			toggle: Function,			
		},
		setup(props) {
			const tempProduct = ref({})			

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {            
				tempProduct.value = JSON.parse(JSON.stringify(props.product))
			})				

			async function deleteFile() {
				tempProduct.value.photo = null
			}

			async function uploadFile(evt) {
				const uploadField = document.getElementById("product_foto");				

				if (uploadField.files[0].size > 2097152) {
					return useToast().warning('The maximum size for a photo is 2mb, please select a smaller file.', { position: "top-right" }); 
				}				

				let loader = loading.show()
				let fileName = await utils.uploadFileHandler('product_photos', evt)				
				loader.hide()

				let url = `https://wmxsoyhjaulrzsxhhihd.supabase.co/storage/v1/object/public/${fileName}`
				
				tempProduct.value.photo = url				
			}

			return {
				tempProduct,
				props,
				uploadFile,
				deleteFile	
			}
		}
	};
</script>
