<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full">
		<div class="text-2xl font-bold pb-4">Inbox</div>
		<OrdersComponent :user="'provider'" :type="'orders'" :orders="orders" :handler="goTo" :empty="'You have no pending orders'"></OrdersComponent>
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'	
	import OrdersComponent from "@/components/OrdersComponent.vue";

	export default {
		name: "Provider_Home",			
		components: {
			OrdersComponent
		},
		setup() {
			const route = useRoute()
			const router = useRouter()

			const orders = ref(null)
			
			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/orders?inbox=true&type=ORDER`)
				.then( res => { 					
					orders.value = res.data.orders
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			function goTo(order) {
				router.push(`/provider/${route.params.provider_id}/order/${order.id}`)				
			}

			return {
				route,
				orders,				
				goTo
			}
		}
	};
</script>
