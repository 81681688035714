import { createStore } from "vuex";

export default createStore({
	state: {
		session: {},
		pro: {}
	},
	getters: {},
	mutations: {
		setSession(state, payload) {
			state.session = payload
		},
		setPro(state, payload) {
			state.pro = payload
		}
	},
	actions: {},
	modules: {},
});
