<template>
	<div class="w-full bg-base-100">      
		<div class="overflow-x-auto">
			<table v-if="!orders" class="table">            
				<thead>
					<tr class="">              
						<th>Order</th>
						<th>Date</th>
						<th v-if="user == 'provider' && type == 'orders' ">Client</th>
						<th v-if="user == 'provider' && type == 'transactions' ">Type</th>
						<th v-if="user == 'provider'">Location</th> 
						<th v-if="user == 'client'">Provider</th>						
						<th>Status</th>            
					</tr>
				</thead>
				<tbody>              
					<tr class="">   
						<td class="w-1/12">
							<div class="skeleton w-12 h-5"></div>						
						</td>
						<td class="w-2/12">
							<div class="skeleton w-12 h-5"></div>
						</td>
						<td class="w-3/12">
							<div class="skeleton w-12 h-5"></div>
						</td>
						<td class="w-3/12">
							<div class="skeleton w-12 h-5"></div>
						</td>
						<td class="w-3/12">
							<div class="skeleton w-12 h-5"></div>
						</td>              
					</tr>
				</tbody>                               
			</table>

			<table v-if="orders && orders.length > 0" class="table">            
				<thead>
					<tr class="cursor-pointer">              
						<th>Order</th>
						<th>Date</th>
						<th v-if="user == 'provider' && type == 'orders' ">Client</th>
						<th v-if="user == 'provider' && type == 'transactions' ">Type</th>
						<th v-if="user == 'provider'">Location</th> 
						<th v-if="user == 'client'">Provider</th>
						<th>Status</th>            
					</tr>
				</thead>
				<tbody>              
					<tr v-for="order in orders" v-bind:key="order.id" class="hover:bg-base-200 cursor-pointer" @click="handler(order)">   
						<td class="w-1/12">						
							{{order.id}}									
						</td>
						<td class="w-2/12">
							{{new Date(order.created_at).toLocaleDateString()}}
						</td>
						<td v-if="user == 'provider' && type == 'orders' " class="w-3/12">
							{{order.client.name}}
						</td>
						<td v-if="user == 'provider' && type == 'transactions' " class="w-3/12">
							{{getTransactionType(order.transaction_type)}}
						</td>
						<td v-if="user == 'provider'" class="w-3/12">
							{{order.location.name}}
						</td>
						<td v-if="user == 'client'" class="w-3/12">
							{{order.provider.name}}
						</td>						
						<td class="w-3/12">
							<StatusComponent :order="order"></StatusComponent>
						</td>              
					</tr>
				</tbody>                               
			</table>
			<div v-if="orders && orders.length == 0" class="text-center card-bordered card-compact rounded-lg shrink-0 w-full bg-base-100">
				<div class="card-body text-center">
					{{empty}}
				</div>
			</div>
		</div>		
	</div>
</template>

<script>
	import { onMounted } from "vue"			
	import StatusComponent from "@/components/StatusComponent.vue";	

	export default {
		name: "ClientDrawer",
		components: {
			StatusComponent
		},	
		props: {
			orders: Object,
			handler: Function,
			empty: String,
			user: String,
			type: String
		},
		setup() {
			

			onMounted( async () => {            
				
			})				

			function getTransactionType(transactionType) {
				switch (transactionType) {
				case "ORDER":
					return "Order"
				case "IN":
					return "In"
				case "OUT":
					return "Out"
				case "ADJUST":
					return "Adjustment"
				}
			}

			return {
				getTransactionType
			}
		}
	};
</script>
