import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import './style.css'
import './toast.css'
import { LoadingPlugin } from 'vue-loading-overlay';
import ToastPlugin from 'vue-toast-notification';
import 'vue-loading-overlay/dist/css/index.css';

createApp(App).use(store).use(router).use(LoadingPlugin).use(ToastPlugin).mount("#app");