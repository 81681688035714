<template>
	<div v-if="client" class="drawer-side">
		<label for="_drawer" aria-label="close sidebar" class="drawer-overlay"></label>
		<div class="bg-white w-full lg:w-80 flex flex-col h-full">		
			<div class="flex-auto overflow-scroll p-6">
				<div class="flex justify-between items-center pb-6">
					<div class="text-2xl font-bold">{{client.id ? "Edit client" : "New client"}}</div>

					<div class="btn btn-square btn-outline btn-sm no-animation">
					<XMarkIcon class="h-5 w-5 cursor-pointer" aria-hidden="true" @click="toggle()"/>  				
					</div>	
				</div>

				<p class="text-sm text-gray-400 pb-2">Name</p>
				<input v-model="tempClient.name" type="text" placeholder="Name" class="bg-white input input-bordered input-sm w-full" />

				<p class="text-sm text-gray-400 pb-2 pt-4">Email</p>
				<input v-model="tempClient.email" type="text" placeholder="Email" class="bg-white input input-bordered input-sm w-full" />							

			</div>
			<div class="p-4 flex-none border-t border-t-gray-300">
				<button @click="handler(tempClient)" class="btn btn-success btn-sm w-full text-white btn-no-animation">Save</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { onMounted, ref } from "vue"		
	import { XMarkIcon } from '@heroicons/vue/24/outline' 		

	export default {
		name: "ClientDrawer",
		components: {
			XMarkIcon
		},	
		props: {
			client: Object,
			handler: Function,
			toggle: Function,			
		},
		setup(props) {
			const tempClient = ref({})			

			onMounted( async () => {            
				tempClient.value = JSON.parse(JSON.stringify(props.client))
			})				
			
			return {
				tempClient,
				props								
			}
		}
	};
</script>
