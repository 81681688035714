<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full">
		<div class="flex justify-between items-center pb-4">	
			<div class="text-2xl font-bold">Orders</div>					
			<label class="btn btn-square btn-outline btn-sm no-animation">
				<router-link :to=" `/client/${route.params.client_id}/order` ">
					<PlusIcon  class="h-5 w-5" aria-hidden="true" />  			
				</router-link>		
			</label>
		</div>
		<OrdersComponent :user="'client'" :orders="orders" :handler="goTo" :empty="'You have no orders'"></OrdersComponent>
	</div>	
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import { PlusIcon } from '@heroicons/vue/24/outline' 
	import OrdersComponent from "@/components/OrdersComponent.vue";

	export default {
		name: "Client_Home",			
		components: {
			PlusIcon, OrdersComponent
		},
		setup() {
			const route = useRoute()
			const router = useRouter()

			const orders = ref(null)
			
			onMounted( async () => {                
				axios.get(`/api/client/${route.params.client_id}/orders`)
				.then( res => { 
					orders.value = res.data.orders
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			function goTo(order) {
				router.push(`/client/${route.params.client_id}/order/${order.id}`)				
			}

			return {
				route,
				orders,
				goTo
			}
		}
	};
</script>
