<template>
	<div class="drawer drawer-end">		
		<input id="location_drawer" type="checkbox" class="drawer-toggle" />
		<div class="drawer-content">
			<div class="p-4 lg:p-6 pt-0 lg:pt-0 h-full">
				<div class="flex justify-between items-center pb-4">
					<div class="flex space-x-4">				
						<button class="btn btn-square btn-outline btn-sm no-animation" @click="utils.goBack(router, `/provider/${route.params.provider_id}/inbox`)">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.0" stroke="currentColor" class="w-5 h-5">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
							</svg>
						</button>
						<div class="text-2xl font-bold">Location</div>
					</div>
					<label v-if="location" for="location_drawer" class="btn btn-square btn-outline btn-sm no-animation">
						<PencilIcon  class="h-5 w-5" aria-hidden="true" />  					
					</label>
				</div>
				<div class="grid grid-cols-1 md:grid-cols-3 space-x-0 md:space-x-8">
					<div class="col-span-2">
						<div class="pb-2 font-bold text-xl">Details</div>
						<div class="w-full bg-base-100  border-t pt-2">      							
							<div>
								<p class="text-sm text-gray-400">Name</p>
								<p class="text-sm" v-if="location">{{location.name}}</p>
								<p class="text-sm w-12 h-5 skeleton" v-if="!location"></p>
							</div>											
						</div>										
					</div>
					<div class="col-span-1 pt-8 md:pt-0">			
						<div class="pb-2 font-bold text-xl">Actions</div>
						<div class="w-full bg-base-100 border-t pt-2">      
							<div class="label cursor-pointer">
								<span class="label-text text-sm">Active</span> 
								<input v-if="location" @change="updateLocation(location, false)" v-model="location.active_temp" type="checkbox" class="toggle" checked />
								<input v-if="!location" type="checkbox" class="toggle skeleton" />
							</div>
						</div>	
						<div class="w-full bg-base-100 pt-2">      
							<div class="label cursor-pointer">
								<span class="label-text text-sm">Default</span> 
								<input v-if="location" @change="updateLocation(location, false)" v-model="location.default_temp" type="checkbox" class="toggle" checked />
								<input v-if="!location" type="checkbox" class="toggle skeleton" />
							</div>
						</div>						
						</div>
					</div>
				</div>
			</div>
		
		<LocationDrawer v-if="location" :location="location" :handler="handleLocation" :toggle="toggle"></LocationDrawer>
	</div>
	
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import { PencilIcon } from '@heroicons/vue/24/outline' 
	import LocationDrawer from '@/components/LocationDrawer.vue' 
	import utils from "@/helpers/utils.js"
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_Location",	
		components: {
			PencilIcon, LocationDrawer
		},		
		setup() {
			const route = useRoute()
			const router = useRouter()
			
			const location = ref(null)

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/location/${route.params.location_id}`)
				.then( res => { 
					location.value = res.data.location
						
					location.value.name_temp = res.data.location.name
					location.value.active_temp = res.data.location.active
					location.value.default_temp = res.data.location.default

				})
				.catch(e => {
					console.log(e)
				}) 				
			})		

			async function updateLocation(updatedLocation, triggerToggle) {
				if (!updatedLocation.name) {
					return useToast().warning('A name is required for a location.', { position: "top-right" });
				}					

				let data = {					
					name: updatedLocation.name,
					active: updatedLocation.active_temp,					
					default: updatedLocation.default_temp,					
				}	
			
				console.log('data', data)

				let loader = loading.show()
				axios.put(`/api/provider/${route.params.provider_id}/location/${route.params.location_id}`, data)
				.then( res => { 					
					location.value = res.data.location					
					location.value.active_temp = res.data.location.active
					location.value.default_temp = res.data.location.default

					useToast().success('The location has been updated.', { position: "top-right" });
					loader.hide()
					
					if (triggerToggle) {
						toggle()	
					}				
				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error updating the location, please try again.', { position: "top-right" });
					console.log(e)
				}) 				
			} 

			function handleLocation(updatedLocation) {
				updateLocation(updatedLocation, true)
			}

			function toggle() {
				const checkbox = document.getElementById('location_drawer');
				checkbox.checked = !checkbox.checked;
			}
			
			return {
				route,
				router,
				utils,
				location,				
				toggle,
				updateLocation,
				handleLocation,
				LocationDrawer,				
			}
		}
	};
</script>
