<template>  
	<div class="w-full h-full flex items-center justify-center">
		<div class="card-bordered rounded-lg shrink-0 w-full max-w-sm bg-base-100">			
			<div class="card-body">
				<div class="text-xl">Login</div>
				<div class="form-control">
					<label class="label">
						<span class="label-text">Email</span>
					</label>
					<input type="email" placeholder="email" v-model="email" class="input input-bordered input-sm" required />
				</div>
				<div class="form-control">
					<label class="label">
						<span class="label-text">Password</span>
					</label>
					<input type="password" placeholder="password" v-model="password" class="input input-bordered input-sm" required @keyup.enter="login"/>					
				</div>
				<div class="form-control mt-6">
					<button class="btn btn-primary btn-sm text-white no-animation" @click="login">Login</button>
				</div>

				<div class="text-sm pt-6 underline cursor-pointer" @click="reset()">
					<p>Forgot my password</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { onMounted, ref } from "vue"
	import { useRouter } from 'vue-router'
	import axios from "@/axios.js"
	import { useStore } from 'vuex'
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "LoginView",
		setup() {
			const store = useStore()
			const router = useRouter()
			const email = ref(null)
			const password = ref(null)

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {


			})

			async function login() {
				if (!email.value || !password.value) {
					return alert('Make sure you filled in all the fields')
				}

				let loader = loading.show()
				axios.post("/api/auth/login", { email: email.value, password: password.value })
				.then( res => {
					loader.hide()
					console.log("Response", res)
					if (res.data) {						
						localStorage.setItem('enlazado_session', JSON.stringify(res.data))					
						store.commit("setSession", res.data)
						router.push(`/provider/${res.data.providers[0].provider.id}/inbox`)
					} else {
						return alert('Error logging in')
					}
				})
				.catch( e => {
					loader.hide()
					console.log(e)
					return alert('Invalid credentials')
				})				
			}

			async function reset() {
				if (!email.value) {
					return alert('Make sure you filled in your email')
				}

				let loader = loading.show()
				axios.post("/api/auth/send_reset", { email: email.value })
				.then( res => {
					loader.hide()
					console.log("Response", res)
					useToast().success('Please check your email for instrucciones to reset your password.', { position: "top-right" });
				})
				.catch( e => {
					loader.hide()
					console.log(e)
					return alert('Error sending password reset link')
				})		
			}

			return {
				login,
				email,
				password,
				reset
			}
		}
	};
</script>